import snarkdown from 'snarkdown';
import assets from './assets/*.png';
import data from './assets/demos.json';

data.forEach(element => {
  const section = document.createElement('section');
  const info = document.createElement('div');
  info.className = 'info';

  const h2 = document.createElement('h2');
  h2.textContent = element.title;
  const p = document.createElement('p');
  p.innerHTML = snarkdown(element.description);
  const links = document.createElement('p');
  links.className = 'links';
  element.links.forEach(a => {
    const link = document.createElement('a');
    link.href = Object.values(a)[0];
    link.textContent = Object.keys(a)[0];
    links.appendChild(link);
  });
  info.appendChild(h2);
  info.appendChild(p);
  info.appendChild(links);

  const preview = document.createElement('div');
  preview.className = 'preview';
  const img = document.createElement('img');
  img.src = assets[element.image.replace('.png', '')];
  img.alt = element.title;
  preview.appendChild(img);

  section.appendChild(info);
  section.appendChild(preview);
  document.body.appendChild(section);
});
